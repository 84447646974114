import React from 'react';
import cn from 'classnames';
import { LogoProduct } from '@dealroadshow/uikit';
import useRouter from '@/Framework/hooks/useNextRouter';
import { TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import Button from '@dealroadshow/uikit/core/components/Button';
import Favicon from './components/Favicon';
import Header from '@/Framework/UI/Pages/Landing/Sections/Header';
import CookiesBanner from '@/Framework/UI/Molecules/CookiesBanner';
import ScrollBodyBackground from '@/Framework/UI/Templates/ScrollBodyBackground';
import Footer from './components/Footer';
import TenantContext from '@/Framework/Tenant/TenantContext';
import config from '@/dataroom/application/config/config';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import stickyFooterStyles from '@/Framework/UI/Organisms/StickyFooter/stickyFooter.scss';
import styles from './tenantDealvdrStyles.scss';

interface IProps {
  children: React.ReactNode,
}

const getHeaderMenu = (pathname) => [
  {
    key: 'features',
    title: 'Features',
    path: '/features',
  },
  {
    key: 'customers',
    title: 'Customers',
    path: '/customers',
  },
  {
    key: 'pricing',
    title: 'Pricing',
    path: '/pricing',
    isActive: () => pathname.startsWith('/pricing'),
  },
];

const TenantDealvdr = ({ children }: IProps) => {
  const tenant = config.tenant.tenantDealvdr.code;
  const { asPath: pathname } = useRouter();

  const loginUrl = dataroomUrl(tenant).getLoginUrl(
    dmPortalUrl.getUrl(`/${ tenant }`),
  );

  return (
    <TenantContext tenant={ tenant }>
      <Favicon />
      <Header
        logoProduct={ LogoProduct.DEALVDR }
        menu={ getHeaderMenu(pathname) }
        customLoginButton={ ({ buttonProps, linkProps }) => (
          <NextNavLink
            { ...linkProps }
            to={ loginUrl }
          >
            <Button { ...buttonProps } />
          </NextNavLink>
        ) }
        headerButton={ ({ buttonProps }) => (
          <NextNavLink
            to="/get-a-quote"
            data-test="getAQuote"
          >
            <Button
              { ...buttonProps }
              title="Get a Quote"
              dataTest="tenantDealvdrGetQuoteButton"
            />
          </NextNavLink>
        ) }
        mobileMenuClassName={ styles.mobileMenu }
      />
      <div className={ cn(styles.pageContainer, stickyFooterStyles.pageContainerWithStickyFooter) }>
        <ScrollBodyBackground tenant={ tenant } minHeight={ 1000 } />
        { children }
      </div>
      <Footer tenant={ tenant } />
      <CookiesBanner />
    </TenantContext>
  );
};

export default TenantDealvdr;
